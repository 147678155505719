.menu {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 35px 0;
  width: 650px;
  transition: all 0.3s ease-in-out;
  @media (max-width: 861px) {
    flex-direction: column;
    padding: 10px 0 0;
    width: 100%;
  }
  &__item {
    position: relative;
    display: flex;
    align-items: center;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #4086ba !important;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 8px;
      height: 50%;
      width: 1px;
      background-color: #4086ba;
      transition: all 0.3s ease-in-out;
    }
    &::before {
      left: -10px;
    }
    &:hover::before {
      left: -30px;
    }

    &::after {
      right: -10px;
    }

    &:hover::after {
      right: -30px;
    }
  }
}
