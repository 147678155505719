body {
  min-width: 320px;
}

.wrapper {
  width: 1200px;
  margin: 0 auto;
  background-color: #ffffff;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
  @media (max-width: 1200px) {
    width: 100%;
  }
}
