.footer {
  width: 100%;
  padding: 20px 0 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  &__icons {
    display: flex;
    justify-content: space-between;
    width: 120px;
    .icon__item svg {
      width: 20px;
      height: 30px;
      fill: #1463aa;
      transition-duration: 0.3s;
    }
    .icon__item svg:hover {
      transform: translateY(-5px);
      cursor: pointer;
    }
  }
  p {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #9d9c9c;
    margin: 10px 0;
    @media (max-width: 420px) {
      font-size: 12px;
    }
  }
}
