@-webkit-keyframes swing {
  15% {
    -webkit-transform: translateX(9px);
    transform: translateX(9px);
  }
  30% {
    -webkit-transform: translateX(-9px);
    transform: translateX(-9px);
  }
  40% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  50% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  65% {
    -webkit-transform: translateX(3px);
    transform: translateX(3px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes swing {
  15% {
    -webkit-transform: translateX(9px);
    transform: translateX(9px);
  }
  30% {
    -webkit-transform: translateX(-9px);
    transform: translateX(-9px);
  }
  40% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  50% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  65% {
    -webkit-transform: translateX(3px);
    transform: translateX(3px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateX(-800px);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}
