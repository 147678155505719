.header {
  &__burger {
    margin: 30px auto 0;
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    width: 2rem;
    height: 2rem;
    padding-top: 1px;
    & div {
      background-color: #4086ba;
      width: 2rem;
      height: 0.1rem;
      transform-origin: 1px;
      transition: all 0.3s ease-in-out;
    }
    @media (min-width: 861px) {
      display: none;
    }
  }
  &__row {
    padding: 30px 60px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @media (max-width: 861px) {
      padding: 30px 30px;
      grid-template-columns: 1fr 1fr;
    }
  }
  &__logo {
    width: 110px;
    height: 40px;
    background: url("../img/logo.png") center/cover no-repeat;
    align-self: center;
    &:hover {
      -webkit-animation: swing 0.6s ease;
      animation: swing 0.6s ease;
      -webkit-animation-iteration-count: 1;
      animation-iteration-count: 1;
    }
    @media (max-width: 425px) {
      margin: 0 auto;
    }
  }
  &__contacts {
    text-align: right;
    align-self: center;
    @media (max-width: 425px) {
      display: none;
    }
  }
  &__phone {
    margin-bottom: 5px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    text-align: right;
    color: #4086ba;
    @media (max-width: 861px) {
      font-size: 16px;
      margin-bottom: 3px;
    }
  }

  &__icon_wrapper {
    display: flex;
    justify-content: center;
    transition: all 0.3s ease-in-out;
  }
  &__icon {
    background: url("../img/phone.png") center/cover no-repeat;
    width: 50px;
    height: 50px;
    transition: all 0.3s ease-out;
    @media (min-width: 425px) {
      display: none;
    }
  }
  &__contacts a {
    position: relative;
    color: #4086ba !important;
    cursor: pointer;
    line-height: 1;
    text-decoration: none;
    &::after {
      display: block;
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 0;
      height: 1px;
      background-color: #4086ba;
      content: "";
      transition: width 0.3s ease-out;
    }
    &:hover:after,
    :focus::after {
      width: 100%;
    }
  }
  &__banner {
    width: 100%;
    height: 410px;
    background: linear-gradient(0deg, #ffffff 0, rgba(255, 255, 255, 0) 100%),
      url("../img/prod.png") center/cover no-repeat;
    overflow: hidden;
    @media (max-width: 861px) {
      background: url("../img/prod.png") 40% center/cover no-repeat;
      -webkit-box-shadow: inset 0px 0px 70px 10px rgba(0, 0, 0, 0.8);
      -moz-box-shadow: inset 0px 0px 70px 10px rgba(0, 0, 0, 0.8);
      box-shadow: inset 0px 0px 70px 10px rgba(0, 0, 0, 0.8);
      transform: scale(0.95);
      border-radius: 5px;
    }
  }
  &__button {
    text-decoration: none;
    background-color: #ffffff;
    outline: none;
    color: #4086ba;
    display: inline-block;
    position: relative;
    padding: 15px 30px;
    border: 1px solid;
    border-image: linear-gradient(
      180deg,
      rgba(64, 134, 186, 1) 0%,
      rgba(70, 157, 222, 1) 70%,
      rgba(81, 172, 240, 1) 100%
    );
    border-image-slice: 1;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    text-transform: uppercase;
    overflow: hidden;
    z-index: 5;
    transition: 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
    @media (max-width: 861px) {
      display: none;
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 0;
      width: 100%;
      z-index: -1;
      color: white;
      background: linear-gradient(
        180deg,
        rgba(64, 134, 186, 1) 0%,
        rgba(70, 157, 222, 1) 70%,
        rgba(81, 172, 240, 1) 100%
      );
      transition: 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    &:hover {
      background: rgba(255, 255, 255, 0);
      color: #ffffff;
    }
    &:hover:before {
      bottom: 0%;
      top: auto;
      height: 100%;
    }
  }
}
