.about {
  width: 100%;
  padding: 50px 0;
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  @media (max-width: 890px) {
    padding: 30px 0;
  }
  &__title {
    margin: 0 auto;
    width: 550px;
    position: relative;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 30px;
    color: #4086ba;
    @media (max-width: 580px) {
      text-align: center;
      font-size: 20px;
      width: 100%;
    }
    @media (max-width: 400px) {
      font-size: 16px;
    }
    & span {
      position: relative;
      color: #ffffff;
      z-index: 1;
      @media (max-width: 580px) {
        color: #4086ba;
      }
    }
    &::after {
      content: "";
      position: absolute;
      width: 82px;
      height: 55px;
      right: 2px;
      top: -12px;
      background: #4086ba;
      z-index: 0;
      @media (max-width: 580px) {
        display: none;
      }
    }
  }
  p {
    width: 550px;
    margin: 0 auto;
    text-align: left;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #9d9c9c;
    @media (max-width: 580px) {
      text-align: center;
      font-size: 12px;
      width: 100%;
    }
    @media (max-width: 400px) {
      font-size: 10px;
    }
  }
  &__slider {
    margin: 70px 0 40px;
    width: 100%;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    @media (max-width: 890px) {
      justify-content: center;
    }
    @media (max-width: 540px) {
      flex-flow: column nowrap;
      align-items: center;
      margin: 0;
    }

    .slider__item {
      width: 200px;
      height: 200px;
      text-align: center;
      @media (max-width: 540px) {
        width: 60%;
        height: 250px;
      }
      @media (max-width: 380px) {
        width: 60%;
        height: 190px;
      }
      &.slider__item-1 {
        background: url("../img/2.png") center/cover no-repeat;
      }
      &.slider__item-2 {
        background: url("../img/1.png") center/cover no-repeat;
        transform: scale(0.9);
      }
      &.slider__item-3 {
        background: url("../img/3.png") center/cover no-repeat;
        @media (max-width: 890px) {
          display: none;
        }
        @media (max-width: 540px) {
          display: block;
        }
      }
      &.slider__item-4 {
        background: url("../img/5.png") center/cover no-repeat;
        @media (max-width: 890px) {
          display: none;
        }
        @media (max-width: 540px) {
          display: block;
        }
      }
      &.slider__item-5 {
        background: url("../img/4.png") center/cover no-repeat;
      }
    }
  }

  &__button {
    text-decoration: none;
    background-color: #ffffff;
    outline: none;
    color: #4086ba;
    display: inline-block;
    position: relative;
    padding: 15px 30px;
    border: 1px solid;
    border-image: linear-gradient(
      180deg,
      rgba(64, 134, 186, 1) 0%,
      rgba(70, 157, 222, 1) 70%,
      rgba(81, 172, 240, 1) 100%
    );
    border-image-slice: 1;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    text-transform: uppercase;
    overflow: hidden;
    z-index: 5;
    transition: 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
    margin-bottom: 11px;
    @media (max-width: 861px) {
      font-size: 16px;
      margin: 0;
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 0;
      width: 100%;
      z-index: -1;
      color: white;
      background: linear-gradient(
        180deg,
        rgba(64, 134, 186, 1) 0%,
        rgba(70, 157, 222, 1) 70%,
        rgba(81, 172, 240, 1) 100%
      );
      transition: 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    &:hover {
      background: rgba(255, 255, 255, 0);
      color: #ffffff;
    }
    &:hover:before {
      bottom: 0%;
      top: auto;
      height: 100%;
    }
  }
}
